import React from "react"

import SEO from "../components/seo"
import {
  Team,
  ContentSections,
  HistoryOfBridge,
  THEMES,
} from "../components/layout"
import { Heading, SectionHeading } from "../components/ui"
import { safelyGetFrontMatter, withFallback, unpackMetadata } from "../utils"

export const AboutUsPageTemplate = ({
  headerText,
  subheaderText,
  pageMeta,
  team,
  sections,
}) => (
  <article>
    <SEO {...unpackMetadata(pageMeta)} />
    <SectionHeading margin="mt5 mb3" hasLink={false}>
      {headerText}
    </SectionHeading>
    <Heading
      tag="3"
      size="f3"
      margin="mt3 mb4"
      color="light-blue"
      fw="fw1"
      className="tc center"
    >
      {subheaderText}
    </Heading>
    <Team team={withFallback(team, [])} />
    {/* Temporary section here until we can add a image + text widget (waiting on MDX 2.0) */}
    <HistoryOfBridge />
    <ContentSections
      sections={sections}
      primaryTheme={THEMES.NEAR_WHITE}
      secondaryTheme={THEMES.WHITE}
    />
  </article>
)

const AboutUsPage = ({ pageContext }) => (
  <AboutUsPageTemplate {...safelyGetFrontMatter(pageContext)} />
)

export default AboutUsPage
