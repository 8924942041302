import React from "react"
import { graphql, StaticQuery } from "gatsby"

import SEO from "../components/seo"
import {
  PageHero,
  ContentSections,
  Testimonial,
  THEMES,
} from "../components/layout"
import {
  safelyGetFrontMatter,
  withFallback,
  safelyGetSiteConfig,
  unpackMetadata,
} from "../utils"

export const ApplyPageTemplate = ({
  applicationsOpen,
  headerImage,
  darkHeaderText,
  sections,
  testimonials,
  headerBodyOpen,
  headerBodyClosed,
  pageMeta,
}) => {
  const darkTheme = THEMES.PURPLE
  const lightTheme = THEMES.NEAR_WHITE

  const [headerColor, bgEvenTheme, bgOddTheme] = darkHeaderText
    ? ["blue", darkTheme, lightTheme]
    : ["white", lightTheme, darkTheme]

  return (
    <article>
      <SEO {...unpackMetadata(pageMeta)} />
      <PageHero
        textColor={headerColor}
        backgroundImage={headerImage}
        md={applicationsOpen ? headerBodyOpen : headerBodyClosed}
      />
      <ContentSections
        sections={sections}
        primaryTheme={bgEvenTheme}
        secondaryTheme={bgOddTheme}
      />

      {withFallback(testimonials, []).map((testimonial, i) => {
        // Last testimonials should always be white on purple
        // For contrast with the footer
        const isLastTestimonial = i === testimonials.length - 1
        const bg = i % 2 === 0 ? "bg-white" : "bg-near-white"
        return (
          <Testimonial
            key={`quote${i}`}
            bg={isLastTestimonial ? "bg-purple" : bg}
            color={isLastTestimonial ? "white" : "blue"}
            image={testimonial.image}
            attribution={testimonial.attribution}
          >
            {testimonial.quote}
          </Testimonial>
        )
      })}
    </article>
  )
}

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          applications {
            dev_app_status
            design_app_status
          }
        }
      }
    }
  }
`

const ApplyPage = ({ pageContext }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const applications = safelyGetSiteConfig(data.sitePage).applications
        const frontmatter = safelyGetFrontMatter(pageContext)
        const applicationStatus = applications[`${frontmatter.type}_app_status`]
        return (
          <ApplyPageTemplate
            headerBodyOpen={frontmatter[`${frontmatter.type}_app_open_text`]}
            headerBodyClosed={
              frontmatter[`${frontmatter.type}_app_closed_text`]
            }
            applicationsOpen={applicationStatus}
            {...frontmatter}
          />
        )
      }}
    />
  )
}

export default ApplyPage
