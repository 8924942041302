import React from "react"

import HomePage from "./home-page.template"
import DefaultPage from "./default-page.template"
import ApplyPage from "./apply-page.template"
import AboutUsPage from "./about-us-page.template"
import SponsorshipPage from "./sponsorship-page.template"
import NotFoundPage from "./not-found.template"
import { AppLayout } from "../app.component"
import { safelyGetFrontMatter, safelyCheckIfPageIsHidden } from "../utils"

const componentTemplateMap = {
  "home-page": HomePage,
  "hidden-page": NotFoundPage,
  "apply-page": ApplyPage,
  "about-us-page": AboutUsPage,
  "sponsorship-page": SponsorshipPage,
}

const CMSTemplate = props => {
  const { pageContext } = props
  const { templateKey } = safelyGetFrontMatter(pageContext)
  const isPageHidden = safelyCheckIfPageIsHidden(pageContext)
  const Page = isPageHidden ? NotFoundPage : componentTemplateMap[templateKey]

  return (
    <AppLayout>
      {Page ? <Page {...props} /> : <DefaultPage {...props} />}
    </AppLayout>
  )
}

export default CMSTemplate
