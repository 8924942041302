import React from "react"

import SEO from "../components/seo"
import { ContentSections, PageHero, Sponsors } from "../components/layout"
import { safelyGetFrontMatter, unpackMetadata } from "../utils"

export const SponsorshipPageTemplate = ({
  headerText,
  headerImage,
  sections,
  pageMeta,
}) => (
  <article>
    <SEO {...unpackMetadata(pageMeta)} />
    <PageHero textColor="blue" backgroundImage={headerImage} md={headerText} />
    <Sponsors />
    <ContentSections sections={sections} />
  </article>
)

const SponsorshipPage = ({ pageContext }) => {
  const frontmatter = safelyGetFrontMatter(pageContext)
  return <SponsorshipPageTemplate {...frontmatter} />
}

export default SponsorshipPage
