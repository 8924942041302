import React from "react"
import { MDXProvider } from "@mdx-js/tag"

import { ContentSections, THEMES } from "../components/layout"
import { SectionBlock, SectionHeading } from "../components/ui"
import { safelyGetFrontMatter } from "../utils"
import {
  CMS_COMPONENTS_SECTION_BODY,
  CMS_SCOPE,
} from "../cms/cms-components.constants"

export const DefaultPageTemplate = ({ title, sections, children }) => {
  return (
    <>
      {sections && (
        <ContentSections
          sections={sections}
          primaryTheme={THEMES.NEAR_WHITE}
          secondaryTheme={THEMES.PURPLE}
        />
      )}
      {!sections && (
        <MDXProvider components={CMS_COMPONENTS_SECTION_BODY} scope={CMS_SCOPE}>
          <SectionBlock color="blue" bg="bg-near-white" align="tc">
            {title ? <SectionHeading>{title}</SectionHeading> : null}
            {children}
          </SectionBlock>
        </MDXProvider>
      )}
    </>
  )
}

const DefaultPage = ({ pageContext, children }) => {
  return (
    <DefaultPageTemplate {...safelyGetFrontMatter(pageContext)}>
      {children}
    </DefaultPageTemplate>
  )
}

export default DefaultPage
