import React from "react"
import { graphql, StaticQuery } from "gatsby"

import SEO from "../components/seo"
import {
  HomeHero,
  ContentSections,
  Sponsors,
  DonateSection,
  BlogPostContainer,
} from "../components/layout"
import {
  safelyGetFrontMatter,
  safelyGetSiteConfig,
  unpackMetadata,
} from "../utils"

export const HomePageTemplate = ({
  title,
  sections,
  devApplicationsOpen,
  designApplicationsOpen,
  pageMeta,
}) => (
  <article>
    <SEO {...unpackMetadata(pageMeta)} />
    <HomeHero
      devApplicationsOpen={devApplicationsOpen}
      designApplicationsOpen={designApplicationsOpen}
      heading={title}
    />
    <Sponsors />
    <ContentSections sections={sections} />
    <BlogPostContainer />
  </article>
)

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          applications {
            dev_app_status
            design_app_status
          }
        }
      }
    }
  }
`

const HomePage = ({ pageContext }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const { dev_app_status, design_app_status } = safelyGetSiteConfig(
          data.sitePage
        ).applications
        const frontmatter = safelyGetFrontMatter(pageContext)
        return (
          <HomePageTemplate
            devApplicationsOpen={dev_app_status}
            designApplicationsOpen={design_app_status}
            {...frontmatter}
          />
        )
      }}
    />
  )
}

export default HomePage
